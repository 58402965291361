.f-14-500 {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.875em !important;
}

.f-16-500 {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 1.75em !important;
}

.f-18-500 {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 1.625em !important;
}

.f-20-400 {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 1.5em !important;
}

.f-20-500 {
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 1.375em !important;
}

.text-primary {
  color: var(--color-kyc-primary) !important;
}
