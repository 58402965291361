.btn-ico {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  font-size: 24px !important;

  &.btn-add {
    color: var(--color-kyc-success) !important;
  }

  &.btn-remove {
    color: #f44336 !important;
  }

  &:hover {
    background-color: #dddddd;
  }
}

.btn-accent {
  background-color: #ff4081 !important;
}
