@font-face {
  font-family: "Poppins";
  src: url("./poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("./poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("./poppins/Poppins-Light.ttf");
}
