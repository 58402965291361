.snack-bar {
  background-color: white !important;

  .snackbar-container {
    display: flex;
    align-items: center;

    .snackbar-icon,
    .snackbar-msg {
      margin: 0 0.25em;

      span {
        color: black;
      }
    }

    .snackbar-msg {
      text-indent: 8px;

      span {
        font-size: 12px;
      }
    }

    .snackbar-actions {
      margin: 0 0 0 0.25em;

      display: flex;

      .snackbar-btn {
        display: flex;
        align-items: center;
        height: 24px;
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 0.25px;
        padding: 0 0.5em;
        margin: 0 0.25em;
        background-color: #bdbdbd;
        color: black;
        outline: none;
        cursor: pointer;
        border-radius: 0.25rem;
        box-sizing: border-box;
      }
    }
  }
}
