/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import "../node_modules/@angular/cdk/overlay-prebuilt.css";

// angular material font-family override
@import "~@angular/material/theming";
$custom-typography: mat-typography-config(
  $font-family: "Poppins, sans-serif",
);
@include angular-material-typography($custom-typography);

:root {
  --color-kyc-primary: #ea7900;
  --color-kyc-success: #004fc2;
  --color-kyc-warning: #f5af1c;
  --color-kyc-grey: #eeeeee;

  --font-weight-kyc-bolder: 600;
  --font-weight-kyc-bold: 500;
  --font-weight-kyc-normal: 400;
  --font-weight-kyc-light: 300;

  --font-size-kyc-h1: 54px;
  --font-size-kyc-h1-mobile: 48px;
  --font-size-kyc-h2: 44px;
  --font-size-kyc-h2-mobile: 36px;
  --font-size-kyc-h3: 30px;
  --font-size-kyc-h4: 24px;
  --font-size-kyc-h5: 20px;
  --font-size-kyc-h6: 18px;
  --font-size-kyc-normal: 16px;
  --font-size-kyc-small: 14px;
  --font-size-kyc-extra-small: 12px;
}

* {
  font-size: 14px;
  font-weight: 400;
}

body {
  font-family: "Poppins", sans-serif !important;
}

.mat-drawer-container {
  background-color: #eeeeee !important;
}

.text-info {
  color: var(--color-kyc-warning) !important;
}

// button style
button {
  &.btn {
    font-size: 1em !important;
  }

  &.btn-success {
    background-color: var(--color-kyc-success) !important;
  }

  &.btn-warning {
    background-color: var(--color-kyc-primary) !important;
  }
}

// material components style
.mat-select {
  font-family: "Poppins", sans-serif !important;
}

.mat-select-arrow {
  width: 0;
  height: 0;
  margin: 0 4px;
  border-top: 7px solid !important;
  border-left: 7px solid transparent !important;
  border-right: 7px solid transparent !important;
}

.mat-form-field-label {
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif !important;
}

.mat-input-element {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif !important;
}

.mat-raised-button[disabled] {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-icon-button[disabled] {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button {
  color: var(--color-kyc-warning);
}

.mat-tooltip {
  font-size: 12px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  text-align: center !important;
  background-color: rgba(0, 0, 0, 0.48) !important;
  color: white !important;
}

.custom-tooltip {
  font-size: 12px !important;
  font-family: "Poppins", sans-serif !important;
  border-radius: 15px !important;
  min-width: 240px !important;

  &.btn-tooltip {
    background-color: rgba(0, 0, 0, 0.36) !important;
  }
}

.input-tooltip {
  position: absolute;
  visibility: hidden;
  bottom: 1.5em;
  left: 7em;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.cdk-global-overlay-wrapper {
  z-index: 1000;
}

.cdk-overlay-container {
  z-index: 2000;
}

.cdk-overlay-pane {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.alert {
  z-index: 2000 !important;
}

// material tab group
.mat-tab-label {
  &.mat-tab-label-active {
    opacity: 1 !important;
  }
}

.mat-tab-label-content {
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: var(--color-kyc-primary) !important;
}

.mat-tab-body-wrapper {
  margin: 16px !important;
  padding: 16px !important;
  background-color: var(--color-kyc-grey) !important;
}

.mat-error {
  font-size: 12px !important;
}

// user product message
.product-msg {
  line-height: 1.5em;
  font-size: var(--font-size-kyc-h5);
  font-weight: var(--font-weight-kyc-bold);
}

@keyframes btnSpinner {
  to {
    transform: rotate(360deg);
  }
}

.btn-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: btnSpinner 0.8s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.puls-icon {
  border-radius: 50%;
  transform: scale(1);
  animation: pulse 1s infinite;
}

// google address auto complete
.pac-container {
  z-index: 9999 !important;
}
