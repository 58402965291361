@mixin shadow-big() {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, $bmd-shadow-penumbra-opacity * 4),
    0 4px 25px 0px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
    0 8px 10px -5px rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

@mixin shadow-big-image() {
  // new box shadow optimized for Tables and Phones
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, $bmd-shadow-ambient-opacity * 2),
    0 8px 10px -5px rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

@mixin shadow-big-navbar() {
  box-shadow: 0 10px 20px -12px rgba(0, 0, 0, $bmd-shadow-penumbra-opacity * 3),
    0 3px 20px 0px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
    0 8px 10px -5px rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

// @mixin shadow-big-color($color){
//     // new box shadow optimized for Tables and Phones
//     box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
//     0 13px 24px -11px rgba($color, 0.60);
// }

@mixin shadow-small-color($color) {
  // new box shadow optimized for Tablets and Phones
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba($color, 0.4);
}

@mixin shadow-navbar-color($color) {
  // new box shadow optimized for Tablets and Phones

  @if ($color == $white-color) {
    box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
  } @else {
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba($color, 0.46);
  }
}

@mixin shadow-2dp() {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, $bmd-shadow-penumbra-opacity),
    0 3px 1px -2px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
    0 1px 5px 0 rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

@mixin shadow-4dp() {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, $bmd-shadow-penumbra-opacity),
    0 1px 10px 0 rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
    0 2px 4px -1px rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

@mixin shadow-6dp() {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, $bmd-shadow-penumbra-opacity),
    0 1px 18px 0 rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
    0 3px 5px -1px rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

@mixin shadow-8dp() {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, $bmd-shadow-penumbra-opacity),
    0 3px 14px 2px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
    0 5px 5px -3px rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

@mixin shadow-16dp() {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, $bmd-shadow-penumbra-opacity),
    0 6px 30px 5px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
    0 8px 10px -5px rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
}

@mixin shadow-2dp-color($color) {
  box-shadow: 0 2px 2px 0 rgba($color, $bmd-shadow-penumbra-opacity),
    0 3px 1px -2px rgba($color, $bmd-shadow-umbra-opacity),
    0 1px 5px 0 rgba($color, $bmd-shadow-ambient-opacity);
}

@mixin shadow-4dp-color($color) {
  box-shadow: 0 4px 5px 0 rgba($color, $bmd-shadow-penumbra-opacity),
    0 1px 10px 0 rgba($color, $bmd-shadow-ambient-opacity),
    0 2px 4px -1px rgba($color, $bmd-shadow-umbra-opacity);
}

@mixin shadow-8dp-color($color) {
  box-shadow: 0 8px 10px 1px rgba($color, $bmd-shadow-penumbra-opacity),
    0 3px 14px 2px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
    0 5px 5px -3px rgba($color, $bmd-shadow-umbra-opacity);
}

@mixin shadow-16dp-color($color) {
  box-shadow: 0 16px 24px 2px rgba($color, $bmd-shadow-penumbra-opacity),
    0 6px 30px 5px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
    0 8px 10px -5px rgba($color, $bmd-shadow-umbra-opacity);
}

@mixin button-shadow-color($color) {
  box-shadow: 0 14px 26px -12px rgba($color, $bmd-shadow-penumbra-opacity * 3),
    0 4px 23px 0px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
    0 8px 10px -5px rgba($color, $bmd-shadow-umbra-opacity);
}

@mixin shadow-z-1() {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}

@mixin shadow-z-1-hover() {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

@mixin shadow-z-2() {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@mixin shadow-z-3() {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

@mixin shadow-z-4() {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

@mixin shadow-z-5() {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
